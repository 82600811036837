import { Stack, useBreakpointValue } from '@chakra-ui/react'
import { Text as DSText } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box, Grid, Hidden } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { FeatureListImage } from '../FeatureList/FeatureListImage'
import { JumbotronProps } from './Jumbotron'
import {
    JumbotronCallToAction,
    JumbotronCallToActionProps,
} from './JumbotronCallToAction'
import { JumbotronContact } from './JumbotronContact'
import { StyledBulletPoints } from 'common/components/molecules/StyledBulletPoints/StyledBulletPoints'

export interface JumbotronWithImageProps
    extends Omit<JumbotronProps, 'variant'> {}

export const JumbotronWithImage: React.FC<JumbotronWithImageProps> = ({
    callToAction,
    secondaryCallToAction,
    subtitle,
    text,
    title,
    image,
    authors,
    contact,
}) => {
    const desktop = useDesktop()
    const variant = useBreakpointValue({
        base: 'secondary',
        xl: 'ghost',
    }) as JumbotronCallToActionProps['variant']
    return (
        <Box
            bgcolor={desktop ? undefined : '#fff'}
            data-testid='JumbotronWithImage'
        >
            <Wrapper>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        {authors && (
                            <DSText
                                variant='subtitle-md'
                                color='monochrome.900'
                                mb='24px'
                                fontWeight='normal'
                            >
                                {authors}
                            </DSText>
                        )}
                        <DSText variant='title-2xl' mb='24px'>
                            {title}
                        </DSText>
                        {subtitle && (
                            <DSText color='monochrome.800' variant='title-md'>
                                {subtitle}
                            </DSText>
                        )}
                        {contact?.contactTitle ? (
                            <JumbotronContact {...contact} />
                        ) : (
                            <Box mt='24px' />
                        )}
                        
                        <StyledBulletPoints>
                            <DSText color='monochrome.700' variant='subtitle-md'>
                                {text}
                            </DSText>
                        </StyledBulletPoints>

                        <Stack
                            direction={{ base: 'column', xl: 'row' }}
                            spacing={{ base: '12px', xl: '28px' }}
                            mt='36px'
                        >
                            {(callToAction?.label ||
                                callToAction?.jumbotronLabel) && (
                                <JumbotronCallToAction
                                    {...callToAction}
                                    noSpacer
                                />
                            )}
                            {(secondaryCallToAction?.label ||
                                secondaryCallToAction?.jumbotronLabel) && (
                                <JumbotronCallToAction
                                    {...secondaryCallToAction}
                                    variant={variant}
                                />
                            )}
                        </Stack>
                        <Spacer variant={desktop ? 'xs' : 'md'} />
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={1} />
                        <Grid item xs={6}>
                            <FeatureListImage image={image} />
                        </Grid>
                    </Hidden>
                </Grid>
            </Wrapper>
        </Box>
    )
}
